import type { WidthType } from '@snapchat/snap-design-system-marketing';
import { Block as BlockSDS } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';

import { Form } from '../../../../components/Form/Form';
import type { ContentfulIdVariable } from '../../../../hooks/useContentfulQuery';
import { useContentfulQuery } from '../../../../hooks/useContentfulQuery';
import { useQueryParams } from '../../../../hooks/useQueryParams';
import { renderRichTextMultiLineWithMarkings } from '../../../../utils/renderText/renderRichText';
import type { ArArkoseProtectedFormBlockQuery } from './arArkoseProtectedFormBlockQuery';
import { query } from './arArkoseProtectedFormBlockQuery';

type ArArkoseProtectedFormProps = {
  id: string;
};

export const ArArkoseProtectedFormBlock: FC<ArArkoseProtectedFormProps> = ({ id }) => {
  const { data } = useContentfulQuery<ArArkoseProtectedFormBlockQuery, ContentfulIdVariable>(
    query,
    {
      variables: { id },
    }
  );

  const searchParams = useQueryParams();

  const utmSource = searchParams.get('utm_source') ?? '';
  const utmMedium = searchParams.get('utm_medium') ?? '';
  const utmCampaign = searchParams.get('utm_campaign') ?? '';
  const utmTerm = searchParams.get('utm_term') ?? '';
  const utmContent = searchParams.get('utm_content') ?? '';

  const fromEmail = utmCampaign === 'mobileflow';

  if (!data?.arArkoseProtectedFormBlock) {
    return null;
  }

  const {
    arkosePublicDevKey,
    arkosePublicProdKey,
    protectedForm,
    anchorId,
    maxColumns,
    subtitle,
    subtitleFromEmail,
    eyebrow,
    titleAlignment,
    title,
    titleFromEmail,
    titleAlignmentMobile,
    widthStyle,
    backgroundColor,
    isNarrow,
  } = data?.arArkoseProtectedFormBlock ?? {};

  const titleForDisplay = fromEmail ? titleFromEmail : title;
  const subtitleForDisplay = fromEmail ? subtitleFromEmail : subtitle;

  return (
    <BlockSDS
      anchorId={anchorId}
      maxColumns={maxColumns ?? undefined}
      subtitle={renderRichTextMultiLineWithMarkings(subtitleForDisplay)}
      eyebrow={eyebrow}
      title={renderRichTextMultiLineWithMarkings(titleForDisplay)}
      titleAlignment={titleAlignment}
      titleAlignmentMobile={titleAlignmentMobile}
      widthStyle={(widthStyle as WidthType) ?? (isNarrow ? 'narrow' : undefined)}
      backgroundColor={backgroundColor}
    >
      {!fromEmail && (
        <Form
          {...protectedForm}
          enableArkose={true}
          arkosePublicDevKey={arkosePublicDevKey}
          arkosePublicProdKey={arkosePublicProdKey}
          extraParams={{ utmContent, utmMedium, utmSource, utmTerm }}
        />
      )}
    </BlockSDS>
  );
};
